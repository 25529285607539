<template>
  <section class="content-container">
      <SectionTabs :tabs="tab_component"/>
      <router-view/>
  </section>
</template>

<!-- <template>
  <div class="view">
    <div v-if="!userHasOnlyOneModule" class="view-sidebar">
      <SideBar />
    </div>
    <div class="view-container" :style="{ width: userHasOnlyOneModule ? '100%' : 'calc(100% - 60px)'}">
      <img src="@/assets/img/forma_1.svg" alt="" class="shape-one" />
      <img src="@/assets/img/forma_3.svg" alt="" class="shape-tw" />
      <img src="@/assets/img/forma_2.svg" alt="" class="shape-tr" />

      <div class="view__body">
        <div class="view__body-top">
          <SectionTitle :title="title" subtitle="" />
          <ProfileNav />
        </div>
        <div class="tabs" style="padding-left: 60px;">
          <SectionTabs :tabs="tab_component" />
        </div>
        <router-view />
        <footerSec></footerSec>
      </div>
    </div>
  </div>
</template> -->

<script>
import { mapGetters } from "vuex";
import SectionTabs from "@/components/Section/SectionTabs.vue";
/* import SideBar from "@/components/SideBar.vue";
import SectionTitle from "@/components/Section/SectionTitle.vue";
import ProfileNav from "@/components/Profile/ProfileNav.vue";
import footerSec from "@/components/Section/SectionFooter.vue"; */

export default {
  components: { 
    SectionTabs,
/*     SideBar, 
    SectionTitle, 
    ProfileNav, 
    footerSec,  */
  },
  data() {
    return {
      title: "Mural Digital",
      tab_component: [
        {
          name: "Dashboard",
          route: "CanalCorporativo-dashboard",
        },
        {
          name: "Canales",
          route: "CanalCorporativo-canales",
        },
        {
          name: "Pantallas",
          route: "CanalCorporativo-pantallas",
        },
      ],
    };
  },

  computed: {
    ...mapGetters("sidebar", [ "userHasOnlyOneModule"]),
  },


  mounted() {
    setInterval(() => {
      this.checkIfUserIsLogged();
    }, 100);
  },
};
</script>